import { graphql } from "gatsby";
import React from "react";
import { Grid } from "theme-ui";
import Layout from "../components/layout";
import ThemeableHeader from "../components/layout/themeable-header";
import NewsletterSignup from "../components/page-blocks/newsletter-signup";
import TwitterCallToAction from "../components/page-blocks/twitter-cta";
import OpenTeaser from "../components/page-blocks/open-teaser";
import Seo from "../components/seo";
import { getFlatFields } from "../lib/text-helpers";

const mergePostsAndSortByDate = (arrayOne, arrayTwo) => {
  const masterArray = arrayOne
    .concat(arrayTwo)
    .sort((a, b) => {
      var dateA = a.post.dateGmt.toUpperCase();
      var dateB = b.post.dateGmt.toUpperCase();
      return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
    })
    .map((post) => post.post);
  return masterArray;
};
const Blogs = ({ data: { posts, timelines } }) => {
  const allLatestPosts = mergePostsAndSortByDate(posts.edges, timelines.edges);
  return (
    <Layout>
      <Seo
        title="Blogs"
        description={
          "The latest thoughts, insight and ideas from both the AQi and a range of other sources. Get in touch if you have a viewpoint or research you think we should include."
        }
      />
      <ThemeableHeader
        color="purple"
        title="Blogs"
        active="/themes"
        pageIntroduction="The latest thoughts, insight and ideas from both the AQi and a range of other sources. Get in touch if you have a viewpoint or research you think we should include."
      />
      <Grid as="section" columns={1} gap={[4, 5, 6]} sx={{ my: [5, 6] }}>
        {allLatestPosts.map((post) => {
          const fields = getFlatFields(post);
          return (
            <OpenTeaser
              key={post.slug}
              title={post.title}
              {...fields}
              src={post?.featuredImage?.node.mediaItemUrl}
            />
          );
        })}
        <TwitterCallToAction />
        <NewsletterSignup />
      </Grid>
    </Layout>
  );
};

export const query = graphql`
  {
    posts: allWpPost(
      sort: { fields: dateGmt, order: DESC }
      filter: { additionalInfo: { hide: { ne: true } } }
    ) {
      edges {
        post: node {
          id
          dateGmt
          title
          slug
          postType: nodeType
          featuredImage {
            node {
              mediaItemUrl
            }
          }
          additionalInfo {
            standfirst
            author
          }
          themeCategories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }

    timelines: allWpTimeline(
      sort: { fields: dateGmt, order: DESC }
      filter: { additionalInfo: { hide: { ne: true } } }
    ) {
      edges {
        post: node {
          id
          postType: nodeType
          dateGmt
          title
          slug
          fields: timelineCustomFields {
            introduction
          }
          additionalInfo {
            standfirst
            author
          }
          featuredImage {
            node {
              mediaItemUrl
            }
          }
        }
      }
    }
  }
`;

export default Blogs;
