import { Box, Flex, Grid, Image, Text } from "@theme-ui/components";
import React from "react";
import AppButton from "../layout/app-button";
import ColumnContainer from "../layout/column-container";
import buildImageUrl from "../../lib/image-boss-helper";

const OpenTeaser = ({
  halfSizeImage = false,
  color = "purple",
  title = "Learning from other nations",
  subtitle,
  label,
  buttonColors,
  icon = "rightArrow",
  cta = "Read more",
  to = "/",
  src,
  srcSet,
  mirror,
  children,
}) => {
  return (
    <ColumnContainer>
      <Grid gap={[4, 5, 5, 5]} columns={[1, null, 2]}>
        <Box sx={{ order: mirror ? [null, null, 1] : null }}>
          {(src || srcSet) && (
            <Image
              sx={{
                display: "block",
                borderRadius: [15, 30],
                aspectRatio: halfSizeImage ? ["2/1", null, "1/1"] : null,
              }}
              src={!srcSet ? buildImageUrl(src, 800) : null}
              srcSet={srcSet}
            />
          )}
        </Box>
        {children ? (
          children
        ) : (
          <Flex
            sx={{
              justifyContent: "flex-end",
              flexDirection: "column",
              alignItems: "flex-start",
              px: [null, null, 0, 5],
            }}
          >
            <Text
              as="h4"
              variant="label"
              sx={{
                color: color,
              }}
            >
              {label}
            </Text>
            <Text as="h3" variant="title" sx={{ my: [2, 3] }}>
              {title}
            </Text>
            <Text
              as="p"
              variant="subtitle"
              sx={{
                width: "80%",
                mb: [2, null, 3],
              }}
            >
              {subtitle}
            </Text>
            <AppButton
              colors={{ ...buttonColors }}
              to={to}
              color={color}
              icon={icon}
            >
              {cta}
            </AppButton>
          </Flex>
        )}
      </Grid>
    </ColumnContainer>
  );
};

export default OpenTeaser;
